@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
body {
    font-family: 'Roboto', sans-serif;
}
.some-class {
    color: #ff0000;
}
.draggable {
    padding: 2px;
    padding-left: 6px;
    padding-right: 6px;
    opacity: 0.5;
    cursor: pointer;
}
.draggable.react-draggable-dragging {
    opacity: 1;
    -webkit-transform: 1s;
            transform: 1s;
}
.draggable p,
.draggable a {
    margin-bottom: 0;
    font-size: 10px;
}

.signature-canvas {
    border: 1px dashed rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-left: 1px;
    margin-right: 1px;
}

@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}



@-webkit-keyframes blinker {
    0%{
        opacity: 0.1
    }
    50%{
        opacity: 0.5
    }
    100%{
        opacity: 0.1
    }
}



@keyframes blinker {
    0%{
        opacity: 0.1
    }
    50%{
        opacity: 0.5
    }
    100%{
        opacity: 0.1
    }
}
